<script setup lang="ts">
import { ref, computed } from 'vue'
import type { Ref } from 'vue'
import { $voixNuxtApi } from '#imports';

export interface Props {
  autoUpload?: boolean
  multiple?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  autoUpload: false,
  multiple: false,
})

const emit = defineEmits(['file-upload'])

// To become the HTML File Element we will utilize for uploading
const fileBrowser = ref(null)
const newFiles: Ref<Array<UploadableFile>> = ref([])

class UploadableFile {
  file: File
  id: string
  url: string
  status: string | null

  constructor(file: File) {
    this.file = file
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
    this.url = URL.createObjectURL(file)
    this.status = null
  }
}

function openFileBrowser() {
  if (fileBrowser.value === null)
    return

  const element = fileBrowser.value as HTMLInputElement
  element.click()
}


function fileExists(otherId: string) {
  return newFiles.value.some(({ id }) => id === otherId)
}

function appendNewFilesFromInput(event: Event) {

  // If we already have files and multiple is requested then return
  if (!props.multiple && newFiles.value.length > 0)
    return

  // If the event target is null then return
  if (event.target === null)
    return

  let filesToAdd = (<HTMLInputElement>event.target).files as FileList;

  let newUploadableFiles = [...filesToAdd]
    .map((file) => new UploadableFile(file))
    .filter((file) => !fileExists(file.id))

  newFiles.value = newFiles.value.concat(newUploadableFiles)
}

const dropActive = ref(false)
function onDrop(event: any) {
  dropActive.value = false

  if (!props.multiple && newFiles.value.length > 0)
    return

  const filesToAdd = [...event.dataTransfer.files] as unknown as FileList
  let newUploadableFiles = [...filesToAdd]
    .map((file) => new UploadableFile(file))
    .filter((file) => !fileExists(file.id))
  newFiles.value = newFiles.value.concat(newUploadableFiles)
}

function removeNewFile(file: UploadableFile) {
  if (fileBrowser.value) {
    const element = fileBrowser.value as HTMLInputElement

    if (!props.multiple)
      element.value = ''

    const index = newFiles.value.indexOf(file)
    if (index > -1) {
      newFiles.value.splice(index, 1)
    }
  }
}

const currentlyUploading: Ref<Array<string | null>> = ref([])

async function uploadFile(file: UploadableFile, url: string) {
  // add the file to the currently uploading list
  currentlyUploading.value.push(file.id)

  // set up the request data
  let formData = new FormData()
  formData.append('file', file.file)

  // track status and upload file
  file.status = 'loading'
  let { data, pending, error } = await $voixNuxtApi(url, { method: 'POST', body: formData })

  // change status to indicate the success of the upload request
  if (!error)
    file.status = 'success'

  // Remove the file from the currently uploading list
  const index = currentlyUploading.value.indexOf(file.id)
  if (index > -1) {
    currentlyUploading.value.splice(index, 1)
  }

  // emit the file upload event
  emit('file-upload', { data, pending, error, file: file.file })

  removeNewFile(file)
  return data
}

function uploadFiles() {
  const url = `/api/voix/files`
  return Promise.all(newFiles.value.map((file) => uploadFile(file, url)))
}

const isUploading = computed(() => currentlyUploading.value.length > 0)
</script>

<template>
  <div>
    <div class="py-4 border-4 rounded-md flex justify-center items-center text-center" :class="{
      'voix-admin-border-light voix-admin-bg-lightest': dropActive,
      'border-slate-200 border-dashed ': !dropActive
    }" :data-active="dropActive" @dragenter.prevent="dropActive = true" @dragover.prevent="dropActive = true"
      @dragleave.prevent="dropActive = false" @drop.prevent="onDrop">
      <div class="flex flex-col items-center space-y-2">
        <input ref="fileBrowser" type="file" class="opacity-0 h-0" :multiple="multiple" @change="appendNewFilesFromInput">

        <button class="voix-admin-bg text-white rounded font-medium p-5 py-1.5 mx-auto" @click="openFileBrowser">
          Browse Files
        </button>
        <p>or drag and drop files here</p>
      </div>
    </div>

    <div v-if="newFiles.length > 0" class=" py-4">
      <div class="uppercase font-medium text-xs pb-1">New Files</div>
      <div v-for="file in newFiles" class="flex flex-col divide-y divide-gray-300">
        <div class="flex items-center space-x-5">
          <div class="py-1 pl-2 float-right">
            <button v-if="!currentlyUploading.includes(file.id)" @click="removeNewFile(file)"
              class="bg-gray-200 py-1.5 px-2 rounded">
              <Icon name="heroicons:trash" class="w-4 h-4" />
            </button>
            <div v-if="currentlyUploading.includes(file.id)" class="bg-gray-200 py-1.5 px-2 rounded">
              <Icon name="heroicons:arrow-path-solid" class="w-4 h-4 animate-spin" />
            </div>
          </div>
          <div class="flex flex-col flex-1">
            <div class="py-1 pr-2 flex items-center space-x-2">
              <div class="flex-none rounded overflow-hidden bg-gray-200 w-10 h-10 flex items-center justify-center">
                <img v-if="file.file.type.includes('image')" :src="file.url" class="w-10 h-10 object-cover" />
                <Icon v-else name="heroicons:document-arrow-up-20-solid" class="w-6 h-6 object-cover text-gray-500" />
              </div>
              <span class="voix-admin-text-dark font-medium text-sm leading-4">
                {{ file.file.name }}
              </span>
            </div>
          </div>
        </div>

      </div>
      <button :disabled="isUploading" @click="uploadFiles"
        class="mt-4 voix-admin-bg text-white rounded font-medium p-5 py-1.5 disabled:opacity-50">Begin
        Upload</button>
    </div>

  </div>
</template>
